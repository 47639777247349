@use "../variables/colors" as color;

/* === HOVER MIXIN ======= */

/* === TRANSITION PACE ===== */
$transition-2: 0.2s;
$transition-3: 0.3s;
$transition-4: 0.4s;
$transition-5: 0.5s;

/* ===== BOX SHADOWS ======*/
$shadow-deep: rgba(0, 0, 0, 0.35) 0px 5px 15px;
$shadow-medium: rgba(0, 0, 0, 0.24) 0px 3px 8px;

@mixin hover($hoverColor: color.$internal-app-primary-medium, $foregroundHoverColor: color.$internal-app-primary-dark) {
    &:focus,
    &:hover {
        background-color: $hoverColor;
        color: $foregroundHoverColor;
        cursor: pointer;
    }
}

/* ======  FOCUS/HOVER MIXIN FOR FORM CONTROLS   */
@mixin formControlHover($hoverColor: color.$internal-app-dark-grey2) {
    &:focus,
    &:hover {
        border: 1px solid $hoverColor;
        cursor: pointer;
    }
}

@mixin show($display) {
    display: $display;
}

@mixin hide() {
    display: none;
}

@mixin collapsed() {
    visibility: collapse;
}

/* ====  TRANSPARENT BACKGROUND ===== */
@mixin transparentBackground() {
    background-color: rgba(256, 255, 255, 0.5);
}

/* === MEDIUM TRANSITION ===== */
@mixin transition-medium($property, $timing) {
    transition: $property $transition-3 $timing;
}

/* === SLOW TRANSITION ===== */
@mixin transition-slow($property, $timing) {
    transition: $property $transition-2 $timing;
}

/* === PACED TRANSITION ===== */
@mixin transition-paced($property, $timing) {
    transition: $property $transition-4 $timing;
}

/* === FAST TRANSITION ===== */
@mixin transition-fast($property, $timing) {
    transition: $property $transition-5 $timing;
}

/* ==== SHADOWS ====  */
@mixin box-shadow-deep() {
    box-shadow: $shadow-deep;
}

@mixin box-shadow-medium() {
    box-shadow: $shadow-medium;
}
