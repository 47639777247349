/* ===== RESET ==== */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
}

li,
ul,
ol {
    list-style: none;
}

a {
    text-decoration: none;
}

.main-container {
    height: 100%;
    width: 100%;
}
