@use "../variables/colors" as colors;
@use "../mixins/mixins.scss" as mixin;

/* ==== FLUID CONTAINER LAYOUT ==== */
.container-flex-fluid {
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 10px;
    min-height: 550px !important;
    border-bottom: 0.5px solid #f6f5ff;
}

.form-header {
    margin: 10px 0px 0px 10px;
}

.header-text {
    color: colors.$internal-app-primary-color;
    font-weight: 600;
    font-size: 18px;
}
