@font-face {
    font-family: "Sitka Banner";
    src: url("../../../../../goldstar-internal/src/assets/fonts/Sitka-Banner.ttf");
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: "Lato-Bold";
    src: url("../../../../../goldstar-internal/src/assets/fonts/Lato-Bold.ttf");
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: "Lato-Medium";
    src: url("../../../../../goldstar-internal/src/assets/fonts/Lato-Medium.ttf");
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: "Lato-Regular";
    src: url("../../../../../goldstar-internal/src/assets/fonts/Lato-Regular.ttf");
    font-weight: normal;
    font-style: normal;
}