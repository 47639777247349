/*  COLORS FOR INTERNAL APP 
    NEW COLOR INTRODUCED MUST BE PLACED IN THIS FILE
*/

/* ====== Primary Colors =========== */

$internal-app-primary-color: #024b81;
$internal-app-primary-medium: #25669f;
$internal-app-primary-light: #f6f5ff;
$internal-app-primary-extra-light: #3769c1;
$internal-app-primary-extra-light: #ebeff2;
$internal-app-primary-dark: #000000;
$internal-app-primary-focus: #4053ff;
$internal-app-primary-light-hover: #3769c1;

/* =====   Secondary Colors =============== */
$internal-app-secondary: #f3dab2;
$internal-app-secondary-medium: #f2dcb5;
$internal-app-secondary-light: #f3dab2;
$internal-app-secondary-dark: #8a7153;
$internal-app-secondary-red: #ff0000;

/* =======  White ======= */
$internal-app-white: #fff;
$internal-app-white-light: #ffffff4a; // CONFIRM COLORS
$internal-app-white-medium: #ffffff69; // CONFIRM COLORS

/* ======   GREY ======= */
$internal-app-grey-dark: #00000029; // CONFIRM COLORS
$internal-app-grey: #0000000f; // CONFIRM COLORS
$internal-app-grey-shadow: #b7b7b7;
$internal-app-grey-medium: #b7b7b7;
$internal-app-grey-light: #f8f8f8;
$internal-app-border-light: #f4f2f2;

/* =====   ERROR ======== */
$internal-app-error: #ff0000;

/* =====  DARK GREY ====== */
$internal-app-dark-grey: #2b2b2b;
$internal-app-dark-grey2: #303030;
$internal-app-grey3: #707070;

/* =====  AQUA ======= */
$internal-app-aqua: #6bcaba;

/* ===== GREEN ======== */
$internal-app-green: #86c142;
$internal-app-green-light: #d5edb3;

/* ======= BASIC COLORS ======= */
$internal-app-transparent: transparent;
$internal-app-opaque: black;

/* ===== RED ======== */
$internal-app-red: red